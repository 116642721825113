body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #423f40;
  /* background-color: #fafafa; */
}

.logo-big {
  font-size: 50px !important;
}

.noline:hover {
  text-decoration: underline;
}

.forcenoline:hover {
  text-decoration: none;
}

.roundcornernocolor {
  border-radius: 10px;
}

.bordererr {
  border-color: #f50000;
}

.disabledtext  {
  color: #a0a0a0;
}

.iconcircle {
  background-color: #fff;
  /* border: 1px solid red; */
  height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
}

#bottomcenter {
  height: 50px;
  position: fixed;
  bottom: 10%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
  z-index: 0;
}

.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}

.defaultfontsize {
  font-size: 20px;
}

.averagefontsize {
  font-size: 18px;
}
.normalsmallfontsize {
  font-size: 16px;
}
.normalsmallerfontsize {
  font-size: 15px;
}
.standardfontsize {
  font-size: 14px;
}
.defaultstandardfontsize {
  font-size: 12px;
}
.defaultsmallfontsize {
  font-size: 10px;
}
.defaultverysmallfontsize {
  font-size: 6px;
}
#redcircledot {
  background: #f00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
  opacity: 80%;
}
.lineentry {
  text-overflow: ellipsis;
  line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.twolineentry {
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.darkgreencolor {
  color: #458e3b;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.announcementpanel,
.prayerpanel {
  border: 1px solid #dddddd;
  background-color: #fff;
  color: #444444;
}
.announcementpanelheader,
.prayerpanelheader {
  color: #000000;
}

.announcementpaneldetails,
.prayerpaneldetails {
  color: #444444;
}

.announcementpanelblue,
.prayerpanelblue {
  border: 1px solid #eaf2fd;
  background-color: #eaf2fd;
  border: 1px solid #dddddd;
  color: #444444;
}

.announcementpanelheaderblue,
.prayerpanelheaderblue {
  color: #277af5;
}

.lightframecolor {
  border: 1px solid #dfe1e4;
}

.lightgreygreenframecolor {
  border: 1px solid #d3e5d0;
}

.redframecolor {
  border: 1px solid #d93d3f;
  background: #f8d7da;
}

.greenframecolor {
  border: 1px solid #51a847;
}

.greenscanindicator {
  border-top: 3px solid #fff;
}

.whitescanindicator {
  border-top: 3px solid #fff;
}

.mycatholicsgred {
  color: #d93d3f;
}

.mycatholicsggreen {
  color: #3d811e;
}

.showvideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  height: 260px;
}

.hidevideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  height: 30px;
}

.showvideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  /* height: 260px; */
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.hidevideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: none;
  height: 0px;
}

.dotlegend {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.image-upload > input {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
}

.image-upload {
  margin-top: -48px;
  margin-left: 140px;
  width: 50px;
}

.image-upload-icon {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 4px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

button:focus {
  outline: 0;
}

.responsive {
  width: 100%;
  height: auto;
  display: inline-block;
}

.zoom {
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
}

.pointer {
  cursor: pointer;
}

.zoom:hover {
  transform: scale(1.01);
  -moz-transform: scale(1.01);
  -webkit-transform: scale(1.01);
  -o-transform: scale(1.01);
  -ms-transform: scale(1.01);
}

.icon-image {
  display: flex;
  flex-direction: column;
  width: 50px;
}

.icon-text {
  display: flex;
  flex-direction: column;
  flex: 0.85;
  flex-wrap: wrap;
  line-height: 1.3em;
}

.hidesection {
  display: none;
}

.showsection {
  display: block;
}

.whitebottomborder {
  border-bottom: 1px solid #fff;
}

.whitetopborder {
  border-top: 1px solid #fff;
}

.appearslowly {
  animation: fadeIn 1.2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1.5;
  }
  100% {
    opacity: 2;
  }
}
.pulsecountry {
  /* width: 100px;
  height: 100px; */
  border-radius: 50px;
  /* background-color: #fff; */
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 2px -2px #28a745;
  }
  to {
    box-shadow: 0 0 2px 2px #28a745;
  }
}

.pulseaward {
  /* width: 100px;
  height: 100px; */
  border-radius: 50px;
  /* background-color: #fff; */
  padding: 5px;
  animation: glowaward 1s infinite alternate;
}

@keyframes glowaward {
  from {
    box-shadow: 0 0 2px -2px #ffcf15;
  }
  to {
    box-shadow: 0 0 2px 2px #ffcf15;
  }
}
.pulsesmallaward {
  animation-name: pulse; /* Refers to the name of your @keyframes element below */
  animation-duration: 1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.pulse-button {
  animation: pulsebutton 2s infinite;
}
.pulse-button:hover {
  animation: none;
}

@keyframes pulsebutton {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
}

.pulsating-circle:before {
  content: "";
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #f50000 !important;
  opacity: 0.5;
  -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1)
    infinite;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  /* background-color: white; */
  border-radius: 15px;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); */
  -webkit-animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
    infinite;
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

@-webkit-keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.clickableentry:hover {
  text-decoration: none !important;
  background-color: #d9e8fa;
}

.clickableentryenable {
  border-radius: 8px;
  text-decoration: none !important;
  background-color: #d9e8fa;
  margin-bottom: 6px;
}

.clickableentry {
  border-radius: 8px;
  text-decoration: none !important;
  background-color: #f6f9f8;
  margin-bottom: 6px;

}

.reactiveiconlite:hover {
  color: #007bff;
}

.reactiveiconlite {
  color: #c0c0c0;
}

.reactiveicondark:hover {
  color: #007bff;
}

.reactiveicondark {
  color: #343a40;
}


@keyframes wiggle {
  0% {transform: rotate(35deg);}
  12.5% {transform: rotate(-30deg);}
  25% {transform: rotate(25deg);}
  37.5% {transform: rotate(-20deg);}
  50% {transform: rotate(15deg);}
  62.5% {transform: rotate(-10deg)}
  75% {transform: rotate(5deg)}
  80% {transform: rotate(0);}  
  85% {transform: rotate(0);}  
  90% {transform: rotate(0);}  
  95% {transform: rotate(0);}  
  100% {transform: rotate(0);}  
}

.wiggle {
display: inline-block;
animation: wiggle 2.5s infinite;
}

.wiggle:hover {
animation: none;
}